// Classes
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'
import { ValueTools }     from '@/Classes/Static/ValueTools'
import { VuexTools }      from '@/Classes/Static/VuexTools'

// Components (.vue)
import BasicHeader from '@/Components/Global/BasicHeader/template.vue'
import SidePanel   from '@/Components/Global/SidePanel/template.vue'
import ModuleBox   from '@/Components/Modules/1/ModuleBox/template.vue'

// Components (Refs)
import { BasicHeaderRef } from '@/Components/Global/BasicHeader/component'
import { SidePanelRef }   from '@/Components/Global/SidePanel/component'

// Constants
import { AppModules }  from '@/Constants/AppModules'
import { AppValues }   from '@/Constants/AppValues' 
import { Breakpoints } from '@/Constants/Breakpoints'
import { VueRouter }   from '@/Constants/VueRouter'
import { Vuex }        from '@/Constants/Vuex'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase  from '@/Mixins/MixinBase'
import MixinFetch from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Export
export default VueMixins(MixinBase, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Views.Modules.EXTERNAL_CPANEL.NAME,

	components: {
		BasicHeader,
		ModuleBox,
		SidePanel
	},

	props: {
		unauthorizedRedirect: Boolean
	},

	data: function() {
		return {
			permissions: {
				[AppValues.ObjectIds.Modules.CHECKLISTS.toUpperCase()]: null as any
			}
		}
	},

	created: function() {
		// Validación para la Redirección no Autorizada.
		const { unauthorizedRedirect } = this.$props
		if (unauthorizedRedirect) this.showToast('Acceso No Autorizado', 'No tienes permisos para acceder al Sistema.', 'danger')

		// Obtención de Permisos y Modulos (Almacenados en Store).
		Store.dispatch('fetchPermissions')
		Store.dispatch('fetchSystems')
	},

	mounted: function() {
		Store.commit('updateViewsViewMode', VueRouter.ViewModes.EXTERNAL)
		this._sidePanel.setStates<SidePanelRef['states']>({ items: AppModules.Global.SidePanelExternalItems, showChoicesList: true })
		this._initPermissions()
	},

	computed: {
		_getSystems: function() {
			// Los Sistemas se almacenarán todos juntos en una misma propiedad.
			const storedSystems: Array<any> = Store.getters.getStoredSystems

			// El filtro será aplicado directamente en la vista, validando que exista al menos un sistema en el array.
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(storedSystems)) {
				// Obtener los Sistemas correspondientes a la constante de los Clientes.
				const externalSystemsIds = Object.keys(AppModules.Global.ExternalModulesList)
				
				// Array que debe contener solamente los Sistemas de los Ids extraidos de la constante.
				const systems = PrimitiveTools.Arrays.getFromStackByProperty(storedSystems, externalSystemsIds, '_idSystem')
				return systems.sort((a: any, b: any) => {
					const indexA = AppModules.Global.ExternalModulesList[a._idSystem]?._params.index
					const indexB = AppModules.Global.ExternalModulesList[b._idSystem]?._params.index
					return (indexA > indexB)  ? 1 : -1
				})
			}
		},

		_basicHeader: function(): BasicHeaderRef {
			return this.$refs.basicHeader as BasicHeaderRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		}
	},

	methods: {
		_getSystemData: function(system: System, property: string, subProperty: string) {
			const a = AppModules.Global.ExternalModulesList[system._idSystem] as any
			const b = a[property]
			return !ValueTools.isNullOrUndefined(subProperty) ? b[subProperty] : b
		},

		_hasPermissionToSystem: function(system: System) {
			return this.permissions[system._idSystem.toUpperCase()]
		},

		_initPermissions: function() {
			const userPermissions = Store.getters.getStoredUserPermissionsAsObject
			this._setPermission(AppValues.ObjectIds.Modules.CHECKLISTS.toUpperCase(), userPermissions.ACCESS_MODULE_40?.privileges.read)
		},

		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Cambios que afectan el aspecto visual de los Componentes (según Breakpoint).
			this._basicHeader.setStates<BasicHeaderRef['states']>({ isMobile: breakpoint <= Breakpoints.Medium })
		},

		_setPermission: function(key: string, value: boolean) {
			this.permissions[key] = value
		},

		onSPChoiceClick: function(item: any) {
			this.$router.push({ name: item.action })
		}
	},

	watch: {
		...VuexTools.watchStoreProperty(Vuex.Modules.Global.Names.Permissions, '_initPermissions')
	}
})

interface System {
	_idSystem: ObjectId
}