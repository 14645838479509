import { render, staticRenderFns } from "./template.vue?vue&type=template&id=0948c2b3&scoped=true&"
import script from "@/Components/Modules/1/ModuleBox/component.ts?vue&type=script&lang=ts&"
export * from "@/Components/Modules/1/ModuleBox/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Components/Modules/1/ModuleBox/style.scss?vue&type=style&index=0&id=0948c2b3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0948c2b3",
  null
  
)

export default component.exports